import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { AD_AUTHORITY_URL } from 'constants/general';
import { Configuration, RedirectRequest } from '@azure/msal-browser';
import { v4 } from 'uuid';
import axios from 'axios';
const mulesoft_hr = process.env.REACT_APP_MULESOFT_HR;

/*
 * Singleton Auth object
 */
export const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority: AD_AUTHORITY_URL + String(process.env.REACT_APP_AD_TENANT_ID), //AD_TENANT_ID,
      clientId: String(process.env.REACT_APP_AD_CLIENT_ID), // AD_CLIENT_ID,
      // postLogoutRedirectUri: process.env.REACT_APP_AD_LOGOUT_URI, // 
      redirectUri: process.env.REACT_APP_AD_REDIRECT_URI, // window.location.origin, //
      validateAuthority: false
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    }
  },
  {
    scopes: ['openid']
  },
  {
    loginType: LoginType.Redirect // It can be Popup or Redirect as per the requirements
    // ,tokenRefreshUri: window.location.origin + '/auth.html',
  }
  
);
//added to handle login error
if (authProvider.authenticationState != "Unauthenticated") {
  const Useremail = localStorage.setItem("userName", authProvider.account.userName);
}

if (authProvider.authenticationState != "Unauthenticated") {
  const userEmail = localStorage.getItem("userName");
  authProvider.acquireTokenSilent({
    scopes: [process.env.REACT_APP_MULESOFT_LOGIN],
    loginHint: userEmail,
  }).then(response => {
    // Use the acquired token
    const accessToken = sessionStorage.setItem("MuleAPIToken", response.accessToken);
    // console.log("jwttoken:", response.accessToken);
    // console.log("authProvider.account :", authProvider.account.userName);
    getMuleApiHrDetails();
  }).catch(error => {
    console.error(error);
  });
}
// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
};
// added to generate random uuid
const correlationID = v4();

const getMuleApiHrDetails = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ` + sessionStorage.getItem('MuleAPIToken'),
    'x-correlation-id': correlationID, 
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
    "Cache-Control": "no-cache",
    "Content-Type": "application/x-www-form-urlencoded",
  };
  return axios.get(mulesoft_hr + authProvider.account.userName,    
    {
      headers: headers,
    }
  ).then(response => {
    sessionStorage.setItem("MuleApiHrData", JSON.stringify(response.data)); 
   // console.log ("mule api result",response.data)
  })
  .catch(error => { console.error(error)});
};